import React from "react";
import vasilis from "../static/images/vasilis2.png";
import overview from "../static/gifs/overview.gif";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Button } from "../components/Button";

const OpenSourcePage = () => {

  // <style>
  //   #deploy {
  //     margin-left: 25%;
  //   }

  //   /* #slack-banner {
  //     -webkit-font-smoothing: antialiased;
  //   font-weight: 300;
  //   text-transform: none;
  //   letter-spacing: 0px;
  //   font-family: "Ek Mukta", sans-serif;
  //   cursor: pointer;
  //   color: inherit;
  //   text-decoration: none;
  //   } */
  //   #slack-banner>div {
  //     -webkit-font-smoothing: antialiased;
  //     font-weight: 300;
  //     text-transform: none;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     background-color: rgb(27, 68, 118);
  //     color: rgb(255, 255, 255);
  //     overflow: hidden;
  //     padding: 24px 16px;
  //     box-sizing: border-box;
  //     padding-left: 72px;
  //     padding-right: 72px;
  //   }

  //   #slack-banner>div>div {
  //     -webkit-font-smoothing: antialiased;
  //     font-weight: 300;
  //     text-transform: none;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     color: rgb(255, 255, 255);
  //     -webkit-box-align: center;
  //     align-items: center;
  //     -webkit-box-pack: justify;
  //     justify-content: space-between;
  //     display: flex;
  //     flex-direction: row;
  //   }

  //   #slack-logo {
  //     -webkit-font-smoothing: antialiased;
  //     font-weight: 300;
  //     text-transform: none;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     color: rgb(255, 255, 255);
  //     -webkit-box-align: center;
  //     align-items: center;
  //     display: flex;
  //     flex-direction: row;
  //   }

  //   #slack-join {
  //     -webkit-font-smoothing: antialiased;
  //     font-weight: 300;
  //     text-transform: none;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     color: rgb(255, 255, 255);
  //     position: relative;
  //     box-sizing: border-box;
  //     margin-top: 0px;
  //     margin-left: 35px;
  //   }

  //   #slack-join>img {
  //     -webkit-font-smoothing: antialiased;
  //     font-weight: 300;
  //     text-transform: none;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     color: rgb(255, 255, 255);
  //     aspect-ratio: auto 160 / 160;
  //     box-sizing: border-box;
  //     height: 160px;
  //     max-width: none;
  //     width: 160px;
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //     display: block;
  //   }

  //   #slack-join>div {
  //     -webkit-font-smoothing: antialiased;
  //     letter-spacing: 0px;
  //     font-family: "Ek Mukta", sans-serif;
  //     cursor: pointer;
  //     border-radius: 30px;
  //     background-color: rgb(255, 255, 255);
  //     color: rgb(224, 84, 31);
  //     position: relative;
  //     padding: 6px 12px;
  //     font-weight: 600;
  //     line-height: 1;
  //     text-transform: uppercase;
  //     white-space: nowrap;
  //     box-sizing: border-box;
  //     font-size: 18px;
  //   }


  //   .mb-1 {
  //     margin-bottom: 4px !important;
  //   }
  // </style>

  return (

<html lang='en'>

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta http-equiv="refresh" content="0; URL=https://www.thousandeyes.com/blog/cisco-announces-acquisition-of-codebgp" />
  <title>Open Source | Code BGP</title>

  {/* <script>
    async function subscribe(id) {
      const email = document.querySelector(`#email${id}`).value;
      const url = "/newsletter";

      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        mode: 'no-cors', // no-cors, *cors, same-origin
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ "email": email }) // body data type must match "Content-Type" header
      });
      const text = await response.json();
      document.querySelector(`#notification${id}`).style.visibility = 'visible';
      if (!text.status.includes('success')) {
        // if ( document.getElementById(`status${id}`).classList.contains('bg-emerald-500') )
        document.getElementById(`status${id}`).style = "background-color: red";
        document.querySelector(`#status${id}`).innerHTML = 'FAIL';
        document.querySelector(`#message${id}`).innerHTML = text.status;
      } else {
        document.getElementById(`status${id}`).style = "background-color: green";
        document.querySelector(`#status${id}`).innerHTML = 'SUCCESS';
        document.querySelector(`#message${id}`).innerHTML = text.status;
      }
    }
  </script> */}
</head>

<body>
</body>

</html>
)
}

export default OpenSourcePage
